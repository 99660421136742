$(document).ready(function(){

  let urls = {};

  const sources = $( 'iframe' ).map(function() {
  console.log(this.src);
  console.log($(this).parent());
  urls[$(this).parent().parent().parent().parent().parent().attr('id')] = this.src
  })

  $(".modal").on('hide.bs.modal', function(){
      $("iframe").attr('src', '');
  });

  $(".modal").on('show.bs.modal', function(){
      $("iframe").attr('src', urls[$(this).attr('id')]);
  });
});
