window.$ = window.jQuery = require('jquery');

// import from packages
import "bootstrap";

// import from components
import "../components/charts";
import "../components/alert";
import "../components/input";
import '../components/tooltip';
import '../components/video_modal';
import { initMapbox } from '../components/init_mapbox';
import { initFullCalendar } from '../components/init_fullcalendar';
import { initFlatpickr } from '../components/init_flatpickr';
import { checkAll, unCheckAll } from '../components/checkAll';

// call functions
initMapbox('map');
initMapbox('map-service-request');
initFullCalendar();
initFlatpickr();
checkAll();
unCheckAll();
