const parseArray = (datasets) => {
  const array = [];
  JSON.parse(datasets).forEach((dataset) => {
    date = Date.UTC(dataset.year, dataset.month - 1, dataset.day)
    array.push([date, dataset.material]);
  });
  return array
}

document.addEventListener('DOMContentLoaded', function () {
  const chartsContainer = document.getElementById('charts-container');
  if (chartsContainer) {
    var Highcharts = require('highcharts');
    require('highcharts/modules/exporting')(Highcharts);
    var data1 = parseArray(chartsContainer.dataset.materials);
    var data2 = parseArray(chartsContainer.dataset.wastes);
    Highcharts.setOptions({
        lang: {
            viewFullscreen: "Plein écran",
            printChart: "Imprimer",
            downloadCSV: "Télécharger au format CSV",
            downloadJPEG: "Télécharger au format JPEG",
            downloadPDF: "Télécharger au format PDF",
            downloadPNG: "Télécharger au format PNG",
            downloadSVG: "Télécharger au format SVG",
            months: [
                'Janvier', 'Février', 'Mars', 'Avril',
                'Mai', 'Juin', 'Juillet', 'Août',
                'Septembre', 'Octobre', 'Novembre', 'Décembre'
            ],
            shortMonths: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Dec'],
            weekdays: [
                'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
                'Jeudi', 'Vendredi', 'Samedi'
            ]
        }
    });
    Highcharts.chart('charts-container', {
      chart:    { type: 'spline'},
      title:    { text: '.' },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: { month: '%b %Y', year: '%Y' },
        title: { text: 'Date' }
      },
      yAxis: {
        title: { text: 'Matière (T)' },
        min: 0
      },
      tooltip: {
        headerFormat: '<b>{series.name}</b><br>',
        pointFormat: '{point.x:%e %b}: {point.y:.2f} T'
      },
      plotOptions: {
        series: { marker: { enabled: true } }
      },
      colors: ['#3FB1CE', '#00692E'],
      series: [{
        name: "Matière",
        data: data1
      }, {
        name: "Déchets",
        data: data2
      }],

      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            plotOptions: {
              series: {
                marker: {
                  radius: 2.5
                }
              }
            }
          }
        }]
      }
    });
  };
});

