function checkAll() {
    
  var checkButton = document.getElementById('checkAll')
  if (checkButton) {
    var checkboxes = document.querySelectorAll('.check-class')
    checkButton.addEventListener("click", function(){ 
      document.getElementById("unCheckAll").checked = false;
      checkboxes.forEach(checkboxe => checkboxe.checked = true);
    })
  }
}



function unCheckAll() {

  var unCheckButton = document.getElementById('unCheckAll')
  if (unCheckButton) {
    var checkboxes = document.querySelectorAll('.check-class')
    unCheckButton.addEventListener("click", function(){ 
      document.getElementById("checkAll").checked = false;
      checkboxes.forEach(checkboxe => checkboxe.checked = false);
    })
  }
}

export { checkAll, unCheckAll };

