const limitNumberValue = () => {
  document.querySelectorAll(".limitNumberValue").forEach((input) => {
    input.addEventListener('change', function (e) {
      const maximumLevel = parseInt(e.currentTarget.max)
      const minimumLevel = parseInt(e.currentTarget.min)
      const currentValue = e.currentTarget.value
      if (currentValue != "-" && currentValue != null ) {
        if ( currentValue < minimumLevel) {
          e.currentTarget.value = minimumLevel
        }else if (currentValue > maximumLevel) {
          e.currentTarget.value = maximumLevel
        }
      }
    })
  })
}
limitNumberValue();
