import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"
const initFlatpickr = () => {
  const datepicker = document.querySelector(".flatpickr");
  if (datepicker) { flatpickr(".flatpickr", {
    "locale": French,
    "dateFormat": "d-m-Y"
  }); }
  const datepickerStartToday = document.querySelector(".flatpickr_today");
  if (datepickerStartToday) { flatpickr(".flatpickr_today", {
    "locale": French,
    "dateFormat": "d-m-Y",
     minDate: "today"
  }); }
};

export { initFlatpickr };
