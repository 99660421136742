import mapboxgl from 'mapbox-gl';
// import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
// import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';


// import 'mapbox-gl/dist/mapbox-gl.css';

const fitMapToMarkers = (map, markers) => {
  const bounds = new mapboxgl.LngLatBounds();
  markers.forEach(marker => bounds.extend([ marker.lng, marker.lat ]));
  map.fitBounds(bounds, { padding: 70, maxZoom: 15, duration: 0 });
};

const initMapbox = (selector) => {
  const mapElement = document.getElementById(selector);

  if (mapElement) {
    mapboxgl.accessToken = mapElement.dataset.mapboxApiKey;
    const map = new mapboxgl.Map({
      container: selector,
      style: 'mapbox://styles/mapbox/streets-v10'
    });

    let link = "delete";
    if (selector === 'map-service-request') { link = "select" }
    const url = `../../projects/markers?link=${link}`;
    fetch(url, { credentials: 'include' })
    .then(response => response.json())
    .then((markers) => {
      markers.forEach((marker) => {
        const popup = new mapboxgl.Popup().setHTML(marker.infoWindow);
        new mapboxgl.Marker()
          .setLngLat([ marker.lng, marker.lat ])
          .setPopup(popup)
          .addTo(map);
      });
      fitMapToMarkers(map, markers);
      // map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken }));
      map.addControl(new mapboxgl.NavigationControl());

    });
  }
};

export { initMapbox };

