import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';

const initFullCalendar = () => {
  var calendarEl = document.getElementById('calendar');
  if (calendarEl) {
    const events = JSON.parse(calendarEl.dataset.events);
    var calendar = new Calendar(calendarEl, {
      plugins: [ dayGridPlugin ],
      locale: 'fr',
      firstDay: 1,
      titleFormat: { year: 'numeric', month: 'short' },
      buttonText: {
        today:    "aujourd'hui",
        month:    'mois',
        week:     'semaine',
        day:      'jour',
        list:     'liste'
      },
      weekends: false,
      events: events,
      eventBackgroundColor: '#00692f',
      eventTextColor: 'white',
      eventBorderColor: '#00692f',
       // eventColor: '#378006',
      // eventClick: function(info) {
      //   alert('Pretation : ' + info.event.title + ' - ' + info.event.start);
      // },
      eventRender: function (info) {
        $(info.el).tooltip({ title: info.event.title });
      }

    });
    calendar.render();
  }
};

export { initFullCalendar };
